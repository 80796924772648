<template>
    <div class="image-wrapper">
        <img :src="embed.sizes.medium" class="img-fluid"/>
    </div>
</template>

<script>
    export default {
        name: 'Home',
        props: {
            embed: String,
        },
        components: {

        }
    }
</script>
